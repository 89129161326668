/* import $ from 'jquery';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';


$(function() {
  $('.wow-scrollbar').each(function() {
    $(this).mCustomScrollbar({
      theme: 'dark-2',
    });
  });
}); */
import $ from 'jquery';
import 'jquery.mmenu';
$(document).ready(function( $ ){
  const $navbarHtml = [$('#wow-menu__mmmenu-adress').html()];
  $('#wow-menu__navmobile').mmenu({
    "navbars": [
      {
        "position": "bottom",
        "content": $navbarHtml,
        "height": 4
      }
   ]
  });
});
window.$ = window.jQuery = require('jquery');
import UIkit from 'uikit';

// import './csbuttons.js';

require('jquery-lazy');

$('img[js-lazyload]').lazy({
  onError: function (el) {
    $(el).attr('src', $(el).data('default'));
  }
});

$('[uk-slider], [uk-slideshow]').each(function() {
  UIkit.util.on(this, 'itemshown', () => {
    $('img[js-lazyload]', this).lazy({
      bind: 'event',
      delay: 0,
      afterLoad: function (el) {
        setTimeout(() => {
          $(el).addClass('js-lazyload-is-loaded');
        }, 200);
      },
      onError: function(el) {
        $(el).attr('src', $(el).data('default'));
      }
    });
  });
});

// $(document).ready(function () {
//   $('.csbuttons').cSButtons();
// });
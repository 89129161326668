import 'slick-carousel';

$('.wow-js-slick-autoplay').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
    breakpoint: 960,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      centerMode: false
      }
    },
    {
        breakpoint: 640,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
    
  ]
});


import $ from 'jquery';
import Vue from 'vue';
import moment from 'moment';
import 'daterangepicker';
import 'jquery-typeahead';

moment.locale('th');
$('.wow-search-form-a').each(function () {
  const component_id = $(this).data('component-id');
  if ($('#vue-wow-search-form-a_' + component_id).length && typeof window['WowSearchHomeC_' + component_id] == "undefined") {
    const inlineDataStartDate = $('#vue-wow-search-form-a_' + component_id + ' [name="daterange"]').data('startdate') || null;
    const inlineDataEndDate = $('#vue-wow-search-form-a_' + component_id + ' [name="daterange"]').data('enddate') || null;
    const inlineDataDestination = $('#vue-wow-search-form-a_' + component_id + ' [name="q"]').val() || null;
    const inlineDataCode = $('#vue-wow-search-form-a_' + component_id + ' [name="code"]').val() || null;
    const inlineDataMonth = $('#vue-wow-search-form-a_' + component_id + ' [name="_month"]').val() || null;

    window['WowSearchHomeC_' + component_id] = new Vue({
      el: '#vue-wow-search-form-a_' + component_id,
      data: {
        formData: {
          startdate: inlineDataStartDate ? moment(inlineDataStartDate).format('YYYY-MM-DD') : null,
          enddate: inlineDataEndDate ? moment(inlineDataEndDate).format('YYYY-MM-DD') : null,
          q: inlineDataDestination || null,
          code: inlineDataCode || null,
          month: inlineDataMonth || '',
        },
        valueDateFormat: 'YYYY-MM-DD',
        displayDateFormat: 'DD MMM YYYY',
      },
      watch: {
        'formData.month': function (month) {
          if (month) {
            this.clearDateRange();
          }
          // console.log(month, );
          /* const dp = $('.js-daterangepicker', this.$el).data('daterangepicker');
          const date = new Date(), y = date.getFullYear(), m = (month - 1);
          dp.minDate = moment(new Date(y, m, 1));
          dp.maxDate = moment(new Date(y, m + 1, 0)); */
          // const minDate = new Date(y, m, 1);
          // const maxDate = new Date(y, m + 1, 0);
          /* $('.js-daterangepicker', this.$el).daterangepicker({
            minDate: minDate,
            maxDate: maxDate,
          }); */
          // const dp = $('.js-daterangepicker', this.$el).data('daterangepicker');
          // console.log(dp);

        },
        'formData.startdate': function (date) {
          if (date) {
            this.clearMonth();
          }
        }
      },
      created: function () {
        this.loadSearchData();
      },
      methods: {
        loadSearchData: function () {
          const dfd = $.Deferred();
          this.dfdSearchData = dfd.promise();
          if (typeof (Storage) !== "undefined" && localStorage.txSearchData) {
            const data = JSON.parse(localStorage.txSearchData);
            dfd.resolve(data);
          } else {
            $.get(window.AJAX_URL, {
              dataType: 'json',
              action: 'getsearchdata',
              _wpnonce: AJAX_NONCE,
            }, function (data) {
              const searchDataString = JSON.stringify(data);
              if (window.isLocalStorageNameSupported() !== false) {
                localStorage.setItem('txSearchData', searchDataString);
              }
              dfd.resolve(data);
            });
          }
        },
        initDestinationTypeahead: function () {
          this.dfdSearchData.done((searchData) => {
            $.typeahead({
              input: '.js-destination-typeahead_' + component_id,
              display: 'name',
              minLength: 1,
              hint: true,
              highlight: false,
              debug: false,
              maxItem: 100,
              offset: false,
              cache: true,
              cancelButton: false,
              source: {
                data: searchData,
              },
              callback: {
                onLeave: () => {
                  this.formData.q = $('.js-destination-typeahead_' + component_id).val() || null;
                },
              },
            });
            $('.js-destination-typeahead_' + component_id).on('change', (e) => {
              this.formData.q = $(e.target).val() || null;
            });
          });
        },
        initDateRangePicker: function () {
          $('.js-daterangepicker', this.$el).daterangepicker({
              autoApply: true,
              autoUpdateInput: false,
              startDate: inlineDataStartDate ? moment(inlineDataStartDate) : false,
              endDate: inlineDataEndDate ? moment(inlineDataEndDate) : false,
              firstDay: 1,
              opens: 'center',
            })
            .on('show.daterangepicker', (e, dp) => {
              if ($(dp.container[0]).parents('.mm-page').length) $(dp.container[0]).appendTo('body');
            })
            .on('apply.daterangepicker', this.setDatePickerValue)
            .on('hide.daterangepicker', (e, picker) => {
              if (!$(e.target).val() && (this.formData.startdate || this.formData.enddate)) {
                this.formData.startdate = null;
                this.formData.enddate = null;
                picker.startDate = moment();
                picker.endDate = moment();
              } else if ($(e.target).val()) {
                this.setDatePickerValue();
              }
            });
          if (this.formData.startdate && this.formData.enddate) {
            this.setDatePickerValue();
          }
        },
        setDatePickerValue: function () {
          const $inputDateRange = $('.js-daterangepicker', this.$el);
          const picker = $inputDateRange.data('daterangepicker');
          $inputDateRange.val(picker.startDate.format(this.displayDateFormat) + ' - ' + picker.endDate.format(this.displayDateFormat));
          this.formData.startdate = picker.startDate.format(this.valueDateFormat);
          this.formData.enddate = picker.endDate.format(this.valueDateFormat);
        },
        onSubmit: function () {
          const data = this.formData;
          for (const propName in data) {
            if (data[propName] === null || data[propName] === '' || data[propName] === undefined) {
              delete data[propName];
            }
          }
          location.href = ($('base').attr('href') || '') + '/search-tour/?' + $.param(data);
        },
        clearDateRange: function () {
          const $inputDateRange = $('.js-daterangepicker', this.$el);
          const picker = $inputDateRange.data('daterangepicker');
          $inputDateRange.val('');
          $inputDateRange.trigger('hide.daterangepicker', picker);
        },
        clearMonth: function () {
          this.formData.month = '';
        }
      },
      mounted: function () {
        this.initDestinationTypeahead();
        this.initDateRangePicker();
      },
    });

  }
});

$('.js-wow-from-hight').click(function(){
  $(this).addClass('wow-search-form-a__form-height-block');
});
$('.js-wow-close-btn').click(function(e){
  e.stopPropagation();
  $('.js-wow-from-hight').removeClass('wow-search-form-a__form-height-block');
});
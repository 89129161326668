import $ from 'jquery';
import moment from 'moment';
import 'daterangepicker';
moment.locale('th');

const setDatePickerValue = function (e) {
  const $inputDateRange = $(e.target);
  const picker = $inputDateRange.data('daterangepicker');
  $inputDateRange.val(picker.startDate.format('DD MMM YYYY') + ' - ' + picker.endDate.format('DD MMM YYYY'));
  const $form = $(e.target).parents('form');
  $form.find('input[name="start_date"]').val(picker.startDate.format('DD MMM YYYY'));
  $form.find('input[name="end_date"]').val(picker.endDate.format('DD MMM YYYY'));
  if($inputDateRange.val()){
    $('.js-form-package-daterangepicker-pickdate-clear').show();
  }else{
    $('.js-form-package-daterangepicker-pickdate-clear').hide();
  }
};
$('.js-form-package-daterangepicker-pickdate').daterangepicker({
  autoApply: true,
  autoUpdateInput: false,
  firstDay: 1,
  opens: 'center',
})
.on('apply.daterangepicker', setDatePickerValue)
.on('hide.daterangepicker', (e, picker) => {
  if(!$(e.target).val()){
    picker.startDate = moment();
    picker.endDate = moment();
    $('.js-form-package-daterangepicker-pickdate-clear').hide();
  }else{
    setDatePickerValue(e);
  }
});
$('.js-form-package-daterangepicker-pickdate-clear').click(function(){
  const $inputDateRange = $('.js-form-package-daterangepicker-pickdate');
  const picker = $inputDateRange.data('daterangepicker');
    $inputDateRange.val('');
    $inputDateRange.trigger('hide.daterangepicker', picker);
});
